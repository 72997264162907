<template>
	<q-page class="flex flex-center column self-center">
		<input id="hash" :value="encode_b64(value)">
		<qrcode-vue v-if="display" :value=" encode_b64(value)" :size="size" level="H" style="max-height: 50vh; width: auto;" />	
		{{encode_b64(value).length}}
	</q-page>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import Tunnel from '../objects/tunnel.js'
//import {token, crypt} from '../libs/keys-generator.js'
import {encode_b64} from '../libs/base64.js'

export default {
	data() {
		return {
			display : false,
			value: {},
			size: 2000,
			awaiting_peers: {} 
		}
	},
	mounted(){

		this.value = {
			type: 'friend',
			tunnels : {
				
			},
		}

		let max_tunnels = 1

		for (let i = 0; i < max_tunnels; i++) {
			
			
			//keep tunnel empty to init a new tunnel
			let tunnel = new Tunnel()

			tunnel.my_peer.on('open', (id) => {

				//console.log('My peer ID is: ' + id);

				this.value.tunnels[id] = tunnel.crypt
				this.awaiting_peers[id] = tunnel.my_peer
				this.connection( tunnel )

				if (i == max_tunnels - 1) {
					console.log('value', this.awaiting_peers );
					this.display = true
				}
			});
		}
	},
	unmounted(){
		console.log('unmounted');

	},
	components:{
		QrcodeVue
	},
	methods : {
		encode_b64,
		connection : function(tunnel){
			tunnel.my_peer.on('connection', function(conn) {
				console.log('conn', conn);
			});
		}
	}
}
</script>

<style>
	#hash{
		width: 100%;
		overflow: hidden;
		max-width: 300px;
	}
</style>