import Dexie from 'dexie';

export const db = new Dexie('owwyea');

db.version(1).stores({
	accounts: '++id, data',
	discussions: '++id, account_id, last_update, users_ids, data', 
	users: '++id, account_id, last_update, tunnels, data', 
	tunnels: '++id, account_id, last_update, my_peer_id, user_peer_id, crypt_key, user_id'
});

db.open();