<template>
	<q-page>
		<!-- <ul>
			<li v-for="friend in friends" :key="friend.id">
				{{ friend.data }}
			</li>
		</ul> -->

		<q-list class="my-card" v-for="index in friends" :key="index">
			<q-item v-ripple clickable>
				<q-item-section avatar >
					<q-avatar>
						<img src="https://cdn.quasar.dev/img/avatar.png">
					</q-avatar>
				</q-item-section>
				<q-item-section>
					<q-item-label lines="1">
					Username
					</q-item-label>
					<q-item-label class="conversation__summary" caption>
						<q-icon name="check"/>
						test
					</q-item-label>
				</q-item-section>
				<q-item-section side>
					<q-item-label caption>
						17:59
					</q-item-label>
					<q-icon name="keyboard_arrow_down" />
				</q-item-section>
			</q-item>
		</q-list>
	</q-page>
</template>

<script>
import { db } from '../objects/db';

export default {
	data(){
		return {
			friends : []
		}
	},
	mounted () {
		
		db.on("ready", () => {

			console.log('db mounted');

			db.users.toArray(
				(data) => {
					console.log(data);
					this.friends = data
				}
			)
		})
	}
}
</script>

<style lang="sass" scoped>
.my-card
	width: 100%

</style>
	
