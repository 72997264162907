<template>
	<q-page>
		<div><input type="" name="" v-model="text_key" @change="join_tunnel_text()"></div>
		<div>
			<div id="qr-code-full-region"></div>
		</div>
	</q-page>
</template>
<script>
	
	import {Html5QrcodeScanner} from 'html5-qrcode'
	import {decode_b64} from '../libs/base64.js'
	import Tunnel from '../objects/tunnel.js'

	export default{
		/*components: {
			QrcodeStream,
			QrcodeDropZone,
			QrcodeCapture
		},*/
		props: {
			qrbox: {
			type: Number,
			default: 250
			},
			fps: {
			type: Number,
			default: 10
			},
		},
		data(){
			return {
				text_key : ''
			}
		},
		mounted(){
			const config = {
				fps: this.fps,
				qrbox: this.qrbox,
			};
			const html5QrcodeScanner = new Html5QrcodeScanner('qr-code-full-region', config);
			html5QrcodeScanner.render(this.onScanSuccess);
			console.log(Html5QrcodeScanner);
		},
		methods: {
			onScanSuccess (decodedText, decodedResult) {
				this.$emit('result', decodedText, decodedResult);
				console.log(decodedText);
				alert(decodedText)
			},
			join_tunnel_text(){
				this.join_tunnel(this.text_key)
			},
			join_tunnel(data){
				
				
				let decode_data = decode_b64(data)
				let opening_tunnels = decode_data.tunnels 

				
				for (const [key, value] of Object.entries(opening_tunnels)) {
					console.log(`${key}: ${value}`);
					let tunnel = new Tunnel(null, key, value)
					console.log(tunnel)
				}
				
				
				
			}
		}
	}

</script>