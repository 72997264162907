<template>
	<q-layout view="lHh Lpr lFf">
		<q-header reveal elevated class="">
			<q-toolbar>
				<q-btn
					flat
					dense
					round
					@click="leftDrawerOpen = !leftDrawerOpen"
					aria-label="Menu"
					icon="menu"
					
				/>

				<q-toolbar-title>
					oowyea
				</q-toolbar-title>

				<div v-if="!menu">.dev</div>
				<q-btn v-else
					flat
					dense
					round
					@click="menu = false"
					aria-label="Menu"
					icon="arrow_back"
				/>
			</q-toolbar>
		</q-header>

		<q-drawer
			v-model="leftDrawerOpen"
			show-if-above
			bordered
			class="bg-grey-2"
		>
			<q-list>
				<q-item clickable tag="a" target="_blank" @click="open_page('join')">
					<q-item-section avatar>
						<q-icon name="qr_code_scanner" />
					</q-item-section>
					<q-item-section>
						<q-item-label>Join</q-item-label>
					</q-item-section>
				</q-item>
				<q-item clickable tag="a" target="_blank" @click="open_page('invite')">
					<q-item-section avatar>
						<q-icon name="person_add" />
					</q-item-section>
					<q-item-section>
						<q-item-label>Invite a Friend</q-item-label>
					</q-item-section>
				</q-item>
				<q-item clickable tag="a" target="_blank" >
					<q-item-section avatar>
						<q-icon name="group_add" />
					</q-item-section>
					<q-item-section>
						<q-item-label>Create a Group</q-item-label>
					</q-item-section>
				</q-item>
				<q-item clickable tag="a" target="_blank" >
					<q-item-section avatar>
						<q-icon name="shop" />
					</q-item-section>
					<q-item-section>
						<q-item-label>Market</q-item-label>
					</q-item-section>
				</q-item>
				<q-item clickable tag="a" target="_blank" >
					<q-item-section avatar>
						<q-icon name="apps" />
					</q-item-section>
					<q-item-section>
						<q-item-label>Get an App</q-item-label>
					</q-item-section>
				</q-item>
				<q-item clickable tag="a" target="_blank" >
					<q-item-section avatar>
						<q-icon name="code" />
					</q-item-section>
					<q-item-section>
						<q-item-label>Create an App</q-item-label>
					</q-item-section>
				</q-item>
				<q-item clickable tag="a" target="_blank" >
					<q-item-section avatar>
						<q-icon name="contact_mail" />
					</q-item-section>
					<q-item-section>
						<q-item-label>Accounts</q-item-label>
					</q-item-section>
				</q-item>
				<q-item clickable tag="a" target="_blank" >
					<q-item-section avatar>
						<q-icon name="settings" />
					</q-item-section>
					<q-item-section>
						<q-item-label>Settings</q-item-label>
					</q-item-section>
				</q-item>
			</q-list>
		</q-drawer>

		<q-page-container>
			<transition-group name="pages" mode="out-in">
				<InviteFriend key="invite" v-if="menu == 'invite'"	/>
				<Join key="join" v-if="menu == 'join'"	/>
				<PNets key="main" v-if="menu == false" />
			</transition-group>
		</q-page-container>
	</q-layout>
</template>

<style>
/*.q-page-container{
	max-height: 100vh;
	overflow: hidden;
}*/
.pages-enter {
	opacity: 0;
	transform: translateY(35px);
}
.pages-enter-active
/*,.pages-leave-active */
{
	transition: all 0.5s ease;
}
.pages-enter-from,
.pages-leave-to {
	opacity: 0;
	transform: translateY(35px);
}

/*.block-enter {
        Opacity: 0;
}
.block-enter-active {
    transition : opacity 2s;
}
.block-leave {
}
.block-leave-active {
    Transition: opacity 2s;
    Opacity: 0;
}*/
</style>

<script>
import { ref } from 'vue'
import PNets from './components/PNets.vue'
import InviteFriend from './components/InviteFriend.vue'
import Join from './components/Join.vue'

//import { db } from './objects/db';




export default {
	name: 'LayoutDefault',

	data(){
		return {
			menu: ''
		}
	},

	components: {
		PNets,
		InviteFriend,
		Join
	},

	mounted (){

		/*db.on("ready", function() {

			console.log('mounted');

			const id = db.users.add({
				data: 'test',
			});

			console.log('id', id);	
		})
		
		db.open();*/

	},

	methods : {
		open_page : function (page){
			this.menu = page
			if( this.$q.platform.is.mobile ){
				this.leftDrawerOpen = false
			}
		}
	},

	setup () {
		return {
			leftDrawerOpen: ref(false)
		}
	}
}
</script>
