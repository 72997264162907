import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import './registerServiceWorker'
//import wb from "./registerServiceWorker";



var app = createApp(App)

//app.provide('$workbox', wb);

app.use(Quasar, quasarUserOptions).mount('#app')
