import Peer from 'peerjs'
import {token, crypt} from '../libs/keys-generator.js'

export default class Tunnel {
	constructor(id = null, dest = null, crypt_key = null){

		let server = {
			host: 'p2p.oowyea.com',
			port: '',
			path: '/',
			secure: true
		}

		this.my_id = id
		if( id == null ){
			this.my_id = token()
		}

		this.crypt = crypt_key
		if( crypt_key == null){
			this.crypt = crypt()
		}
		
		this.my_peer = new Peer( id, server )

		this.my_peer.on('open', (id) => {
			console.log('open my peer '+id)

			if( dest )
			{
				this.user = this.my_peer.connect(dest)
				console.log('dest', dest)
				this.user.on('open', () => {
					console.log('user open')
				})
			}

		}).on('connection', ( dataConnection ) => {
			console.log('connection on my peer ', dataConnection)
		})
	}
}