var rand = function() {
    return Math.random().toString(36).substr(2); // remove `0.`
};

var token = function() {
    return rand() + rand() + rand(); // to make it longer
};

var crypt = function() {
    return rand(); // to make it longer
};


export {token, crypt}